import routes from "./routes.js";
import footer from "./footer.js";
import login from "./login.js";
import ads from "./ads.js";

const config = {
  site: "PromoQui",
  country: "it",
  locale: "it",
  language: "it-IT",
  env: "staging",
  settings: { disableImagesLazyLoad: false },
  maxDistance: 30000,
  frontendDomain: "https://www.promoqui.it",
  dataAsset: {
    url: "https://data.promoqui.it",
  },
  threshold: {
    store: {
      small: 6,
      medium: 25,
    },
  },
  // gql: {
  //   device: "PROMOQUI_WEB",
  //   apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
  //   domainGql: "https://apiv3-testing-n.promoqui.it/graphql?",
  //   domainGqlAppauth: "https://apiv3-testing-n.promoqui.it/graphql/appauth",
  //   domainGqlAuthenticated:
  //     "https://apiv3-testing-n.promoqui.it/graphql/authenticated",
  // },
  gql: {
    device: "PROMOQUI_WEB",
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domainGql: "https://apiv3-testing.promoqui.it/graphql",
    domainGqlAppauth: "https://apiv3-testing.promoqui.it/graphql/appauth",
    domainGqlAuthenticated:
      "https://apiv3-testing.promoqui.it/graphql/authenticated",
  },
  geolocation: {
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domain: "https://staging-api.promoqui.it/geolocation/",
  },
  socialLinks: {
    facebook: "https://www.facebook.com/PromoQui",
    twitter: "https://www.twitter.com/promoqui",
    googlePlus: "https://plus.google.com/+PromoquiItSpa",
    pinterest: "https://it.pinterest.com/promoqui/",
  },
  apple: {
    clientId: "com.web.promoqui",
    appId: "466421194",
  },
  android: {
    appId: "it.promoqui.android",
  },
  onetrust: {
    script: {
      async: false,
      src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
      type: "text/javascript",
      dataDomainScript: "ae38d11d-cdda-4c5c-8d4c-1a0436e0ac21-test",
    },
    onetrustConsent: "TCF2",
    privacyPolicyURL:
      "https://legal.shopfully.cloud/it-it/privacy-policy-pq.html",
    cookiePolicyURL: "https://staging.promoqui.it/cookie-policy",
  },
  google: {
    googleMaps: "AIzaSyBtalFrzwZsFhlU3PIf00vAIOv31GpmiaQ",
    googleClientID:
      "691043485490-0bkie4tpavs054h3t2uk7ed67sfu2uaf.apps.googleusercontent.com",
  },
  sentry: {
    enabled: true,
    dsn: "https://4812d27177684cb1b81c984d9bb61776@o150860.ingest.sentry.io/6726709",
  },
  appStore: {
    bannerApp: true,
    apple: "https://itunes.apple.com/it/app/promoqui/id466421194?mt=8",
    android:
      "https://play.google.com/store/apps/details?id=it.promoqui.android",
    windows: "https://www.microsoft.com/it-it/store/p/promoqui/9nblggh091xj",
  },
  gAnalytics: {
    gtmId: "GTM-MB4CM68",
    general: {
      id: "UA-25234055-1",
      id4: "G-Y45RLWXXSC",
      label: "general",
    },
    trackingPage: {
      id: "UA-25234055-20",
      label: "leafletViewerTracker",
    },
  },
  shopfully: {
    api: {
      openStreetMap: "https://pq-maps.shopfully.cloud/styles/klokantech-basic",
    },
  },
  enableJSONLD: {
    retailer: true,
  },
};

export default {
  ...config,
  routes: routes,
  footer: footer,
  ads: ads,
  login: login,
};
