var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"release-1732277825"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/pq-fe-site-it";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import getConfs from "@/commons/confs";

const confs = getConfs(process.env.NEXT_PUBLIC_COUNTRY);

if (confs.sentry.enabled && process.env.NODE_ENV != "development") {
  const SENTRY_DSN =
    confs.sentry.dsn ||
    process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN;

  Sentry.init({
    release: process.env.SENTRY_RELEASE,
    dsn:
      SENTRY_DSN ||
      "https://4812d27177684cb1b81c984d9bb61776@o150860.ingest.sentry.io/6726709",
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: false,
      }),
    ],
    // Called for message and error events
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (error && error.message) {
        if (
          /**
           * error that we were unable to reproduce and that could be related to the InitServerSide,
           * probably when reaching the page via internal routing
           */
          error.message.match(/Cannot read property 'country' of undefined/i)
        ) {
          console.log("[ERROR][SENTRY][FILTERED]:", error.message);
          return null;
        } else if (
          /**
           * embla carousel error
           * "undefined is not an object (near '...diff)-a(t.diff)})[0].index,distance:d}),...')"
           */
          error.message.match(/index,distance:d/i)
        ) {
          console.log("[ERROR][SENTRY][FILTERED]:", error.message);
          return null;
        } else if (
          /**
           * SecurityError related to localStorage access denied
           * "Failed to read the 'localStorage' property from 'Window': Access is denied for this document."
           */
          error.message.match(
            /Failed to read the 'localStorage' property from 'Window'/i,
          )
        ) {
          console.log("[ERROR][SENTRY][FILTERED]:", error.message);
          return null;
        } else if (
          /**
           * third party error
           */
          error.message.match(
            /Cannot read properties of undefined \(reading 'index'\)/i,
          )
        ) {
          console.log("[ERROR][SENTRY][FILTERED]:", error.message);
          return null;
        } else if (
          /**
           * only occurs on older safari browsers that don't support IntersectionObserver
           */
          error.message.match(/IntersectionObserver/i)
        ) {
          console.log("[ERROR][SENTRY][FILTERED]:", error.message);
          return null;
        }
      }

      return event;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    denyUrls: [
      /adsbygoogle/gi,
      /.mjs/gi,
      /ampproject/gi,
      /rocket-loader/gi,
      /beacon/gi,
      /publishertag/gi,
    ],
  });
}
